import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import SvgIcon from "@mui/material/SvgIcon";
import CancelIcon from '@mui/icons-material/Cancel';
import { AdriaLogo, ConversionIcon, ReimoLogo, RentalLogo, ShoppingCartIcon, SunLivingLogo, LippertLogo } from './Icons';
import { useTranslation } from 'react-i18next';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 550,
    },
  },
};




function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function Filter2(props) {
	const { t, i18n } = useTranslation("",{lng: props.language});
  const theme = useTheme();
  const [filters, setFilters] = React.useState(props.activeFilters);
  
  const handleChange = (event) => {
    const { target: { value },} = event;	
    let x =       typeof value === 'string' ? value.split(',') : value;

    setFilters(
      typeof value === 'string' ? value.split(',') : value,
    );
    
		let a = [...props.currentFilters];
		a.map(function(f) {
				f.visible = false;
				if(x.includes(f.label))
					f.visible = true;

		});
		props.setCurrentFilters(a);

  };
  
	const handleChipDelete = function(e) {
		let x = [...filters];
		let a1 = [];
		x.map(function(f) {
			if(f != e)
				a1.push(f);
		});
		setFilters(a1);
		
		let a = [...props.currentFilters];
		a.map(function(f) {
				if(e == f.label)
					f.visible = false;

		});
		props.setCurrentFilters(a);		
	}

  
	const fixIconNames = function(i) {
		if(i == "Adria Wohnmobile, Vans & Caravans")
			return  <SvgIcon><AdriaLogo/></SvgIcon>
		if(i == "Ausbau")
			return  <SvgIcon><ConversionIcon/></SvgIcon>
		if(i == "Reimo Fahrzeuge")
			return  <SvgIcon><ReimoLogo/></SvgIcon>
		if(i == "Zubehör")
			return  <SvgIcon><RentalLogo/></SvgIcon>
		if(i == "ShoppingCartIcon")
			return  <SvgIcon><ShoppingCartIcon/></SvgIcon>
		if(i == "Sun Living")
			return  <SvgIcon><SunLivingLogo/></SvgIcon>
		if(i == "LippertLogo")
			return  <SvgIcon><LippertLogo/></SvgIcon>
	  
	}
	

  return (
    <div>
      <FormControl sx={{ m: 1, width: "99%" }}>
        <InputLabel id="demo-multiple-chip-label" >{t('bitte auswählen...')}</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={filters}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label={t('bitte auswählen...')} />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1.5 }}>
              {selected.map((value) => (
			    <Chip
				    key={value} 
				    
				    onDelete={(e) => handleChipDelete(value)}
			      label={
			          <span className="w-full truncate">{t(value)}</span>
			      }
			      sx={{borderRadius: "4px", backgroundColor: '#fff', border: "1px solid #aaa"}}
			      icon={         
					  <IconButton className="h-5 w-5"  size="small">
			            {fixIconNames(value)}
			          </IconButton>
					}
			      deleteIcon={
			        <div>
			          <IconButton  className="h-5 w-5" size="small">
			          	<CancelIcon className="h-5 w-5" 
						          	onMouseDown={(event) => event.stopPropagation()} 
			          				sx={{margin: "-30px 2px 0 15px",position: "absolute", backgroundColor: '#fff'}}
    					/>
			          </IconButton>
			        </div>
			      }
			      />                		
                		
                		
                		
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {props.currentFilters.map((f) => (
            <MenuItem
              key={f.label}
              value={f.label}
            >
              {t(f.label)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}